<template>
  <div class="text-caption">
    <label v-for="(item, index) in textList" :key="index" :class="item.type === 'bold' && 'font-weight-black'">
      {{ item.label }}
    </label>
    <!-- *加成等级与加成特效是
    <label class="font-weight-black">选填</label>
    的，当加成等级为
    <label class="font-weight-black">0</label>
    或
    <label class="font-weight-black">非数字</label>
    时，将会
    <label class="font-weight-black">自动忽略</label>
    加成特效 -->
  </div>
</template>

<script>

export default {
  props: {
    text: {
      type: String,
      default: ''
    }
  },
  computed: {
    textList () {
      return this.text.split('**').map((item, index) => {
        return {
          label: item,
          type: index % 2 === 1 ? 'bold' : ''
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
