import * as Assets from 'utils/type'
import db from 'utils/db'

// 将数据全部收集到 $mod 中
function $mod () {
  const mod = Object.keys(Assets).reduce((obj, name) => {
    // const varName = name[0].toLowerCase() + name.slice(1)
    obj[name] = Assets[name]
    return obj
  }, {})
  return Object.freeze(mod)
}

function $db () {
  return Object.freeze(db)
}

export default {
  computed: {
    $mod,
    $db
  }
}
