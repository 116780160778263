// 神器槽位
export const Slot = {
  1: {
    label: '头盔',
    value: 1
  },
  2: {
    label: '项链',
    value: 2
  },
  3: {
    label: '胸甲',
    value: 3
  },
  4: {
    label: '披风',
    value: 4
  },
  5: {
    label: '武器',
    value: 5
  },
  6: {
    label: '盾牌',
    value: 6
  },
  7: {
    label: '挂饰',
    value: 7
  },
  8: {
    label: '项链',
    value: 8
  },
  9: {
    label: '战靴',
    value: 9
  },
  10: {
    label: '腿甲',
    value: 10
  }
}

// 神器等级
export const Quality = {
  1: {
    label: '基础',
    value: 1
  },
  2: {
    label: '中等',
    value: 2
  },
  3: {
    label: '进阶',
    value: 3
  },
  4: {
    label: '伟大',
    value: 4
  },
  5: {
    label: '终极',
    value: 5
  }
}

// 神器套装部件
export const Part = {
  0: {
    label: '无套装加成',
    desc: '无',
    value: 0
  },
  1: {
    label: '幻术师',
    desc: '幻术师',
    value: 1
  },
  2: {
    label: '猎兔',
    desc: '猎兔',
    value: 2
  },
  3: {
    label: '泰坦',
    desc: '泰坦',
    value: 3
  },
  4: {
    label: '曼德尔',
    desc: '曼德尔',
    value: 4
  },
  5: {
    label: '海牙',
    desc: '海牙',
    value: 5
  },
  6: {
    label: '威德',
    desc: '威德',
    value: 6
  },
  7: {
    label: '回声',
    desc: '回声',
    value: 7
  }
}

// 神器特效
export const Effect = {
  Spellpower: {
    label: '法强',
    value: 'Spellpower'
  },
  Defense: {
    label: '防御',
    value: 'Defense'
  },
  Attack: {
    label: '攻击',
    value: 'Attack'
  },
  Knowledge: {
    label: '知识',
    value: 'Knowledge'
  },
  Luck: {
    label: '幸运',
    value: 'Luck'
  },
  Movement: {
    label: '移速',
    value: 'Movement'
  },
  Morale: {
    label: '士气',
    value: 'Morale'
  },
  Sight: {
    label: '视野',
    value: 'Sight'
  },
  Speed: {
    label: '速度',
    value: 'Speed'
  },
  Gold: {
    label: '金币',
    value: 'Gold'
  },
  Ore: {
    label: '矿物',
    value: 'Ore'
  },
  Wood: {
    label: '木材',
    value: 'Wood'
  },
  Mercury: {
    label: '水银',
    value: 'Mercury'
  },
  Crystal: {
    label: '水晶',
    value: 'Crystal'
  },
  Gems: {
    label: '宝石',
    value: 'Gems'
  },
  Sulphur: {
    label: '硫磺',
    value: 'Sulphur'
  },
  'Call of the Wild': {
    label: '荒野召唤',
    value: 'Call of the Wild'
  },
  'Call Elements': {
    label: '元素召唤',
    value: 'Call Elements'
  }
}

// 技能
export const Skill = {
  Armorer: {
    label: 'Armorer',
    desc: '',
    value: 'Armorer'
  },
  Offense: {
    label: 'Offense',
    desc: '',
    value: 'Offense'
  },
  Archery: {
    label: 'Archery',
    desc: '',
    value: 'Archery'
  },
  Warding: {
    label: 'Warding',
    desc: '',
    value: 'Warding'
  },
  Hydromancy: {
    label: 'Hydromancy',
    desc: '',
    value: 'Hydromancy'
  },
  Terramancy: {
    label: 'Terramancy',
    desc: '',
    value: 'Terramancy'
  },
  Aeromancy: {
    label: 'Aeromancy',
    desc: '',
    value: 'Aeromancy'
  },
  Pyromancy: {
    label: 'Pyromancy',
    desc: '',
    value: 'Pyromancy'
  },
  Diplomacy: {
    label: 'Diplomacy',
    desc: '',
    value: 'Diplomacy'
  },
  Leadership: {
    label: 'Leadership',
    desc: '',
    value: 'Leadership'
  },
  Estates: {
    label: 'Estates',
    desc: '',
    value: 'Estates'
  },
  Healing: {
    label: 'Healing',
    desc: '',
    value: 'Healing'
  },
  Sorcery: {
    label: 'Sorcery',
    desc: '',
    value: 'Sorcery'
  },
  Recruitment: {
    label: 'Recruitment',
    desc: '',
    value: 'Recruitment'
  },
  Learning: {
    label: 'Learning',
    desc: '',
    value: 'Learning'
  },
  Logistics: {
    label: 'Logistics',
    desc: '',
    value: 'Logistics'
  },
  Fortune: {
    label: 'Fortune',
    desc: '',
    value: 'Fortune'
  },
  Demonology: {
    label: 'Demonology',
    desc: '',
    value: 'Demonology'
  },
  Necromancy: {
    label: 'Necromancy',
    desc: '',
    value: 'Necromancy'
  },
  Wisdom: {
    label: 'Wisdom',
    desc: '',
    value: 'Wisdom'
  },
  Scouting: {
    label: 'Scouting',
    desc: '',
    value: 'Scouting'
  },
  Mysticism: {
    label: 'Mysticism',
    desc: '',
    value: 'Mysticism'
  },
  Planning: {
    label: 'Planning',
    desc: '',
    value: 'Planning'
  },
  Summoning: {
    label: 'Summoning',
    desc: '',
    value: 'Summoning'
  },
  Plunder: {
    label: 'Plunder',
    desc: '',
    value: 'Plunder'
  },
  Engineering: {
    label: 'Engineering',
    desc: '',
    value: 'Engineering'
  },
  Royalty: {
    label: 'Royalty',
    desc: '',
    value: 'Royalty'
  },
  Combat: {
    label: 'Combat',
    desc: '',
    value: 'Combat'
  },
  Tactics: {
    label: 'Tactics',
    desc: '',
    value: 'Tactics'
  },
  Creation: {
    label: 'Creation',
    desc: '',
    value: 'Creation'
  },
  Champion: {
    label: 'Champion',
    desc: '',
    value: 'Champion'
  },
  Dragonking: {
    label: 'Dragonking',
    desc: '',
    value: 'Dragonking'
  },
  Potency: {
    label: 'Potency',
    desc: '',
    value: 'Potency'
  },
  Legion: {
    label: 'Legion',
    desc: '',
    value: 'Legion'
  },
  Toxicology: {
    label: 'Toxicology',
    desc: '',
    value: 'Toxicology'
  },
  'Midas Touch': {
    label: 'Midas Touch',
    desc: '',
    value: 'Midas Touch'
  },
  Elementalism: {
    label: 'Elementalism',
    desc: '',
    value: 'Elementalism'
  },
  Bloodlust: {
    label: 'Bloodlust',
    desc: '',
    value: 'Bloodlust'
  },
  Swarming: {
    label: 'Swarming',
    desc: '',
    value: 'Swarming'
  },
  Bloodwarping: {
    label: 'Bloodwarping',
    desc: '',
    value: 'Bloodwarping'
  },
  Oligarchy: {
    label: 'Oligarchy',
    desc: '',
    value: 'Oligarchy'
  },
  Destruction: {
    label: 'Destruction',
    desc: '',
    value: 'Destruction'
  },
  Recalcitrance: {
    label: 'Recalcitrance',
    desc: '',
    value: 'Recalcitrance'
  },
  Mastery: {
    label: 'Mastery',
    desc: '',
    value: 'Mastery'
  },
  Hex: {
    label: 'Hex',
    desc: '',
    value: 'Hex'
  },
  'Blight Ritual': {
    label: 'Blight Ritual',
    desc: '',
    value: 'Blight Ritual'
  },
  Mercurial: {
    label: 'Mercurial',
    desc: '',
    value: 'Mercurial'
  },
  Bodyguards: {
    label: 'Bodyguards',
    desc: '',
    value: 'Bodyguards'
  },
  Devour: {
    label: 'Devour',
    desc: '',
    value: 'Devour'
  },
  'Dizzy Tune': {
    label: 'Dizzy Tune',
    desc: '',
    value: 'Dizzy Tune'
  }
}

// 神器类型
export const Oriented = {
  0: {
    label: '法术',
    value: 0
  },
  1: {
    label: '力量',
    value: 1
  }
}

export const GoldCost = {
  75: {
    label: '75 金币',
    desc: '重量 0/力量 2/伤害 5.5/生命 31/尺寸 3/速度 5',
    value: 75
  },
  85: {
    label: '85 金币',
    desc: '重量 0.5/力量 2/伤害 6.6/生命 40.7/尺寸 3/速度 5',
    value: 85
  },
  120: {
    label: '120 金币',
    desc: '重量 1/力量 3/伤害 7.8/生命 53.5/尺寸 3/速度 5',
    value: 120
  },
  185: {
    label: '185 金币',
    desc: '重量 1.5/力量 3/伤害 9.2/生命 68.7/尺寸 3/速度 5',
    value: 185
  },
  285: {
    label: '285 金币',
    desc: '重量 2/力量 5/伤害 10.6/生命 86/尺寸 4/速度 6.7',
    value: 285
  },
  425: {
    label: '425 金币',
    desc: '重量 2.5/力量 6/伤害 12.1/生命 105.4/尺寸 4/速度 6.7',
    value: 425
  },
  615: {
    label: '615 金币',
    desc: '重量 3/力量 8/伤害 13.6/生命 126.9/尺寸 4/速度 6.7',
    value: 615
  },
  855: {
    label: '855 金币',
    desc: '重量 3.5/力量 10/伤害 15.2/生命 150.4/尺寸 5/速度 8.3',
    value: 855
  },
  1155: {
    label: '1155 金币',
    desc: '重量 4/力量 14/伤害 16.8/生命 175.7/尺寸 5/速度 8.3',
    value: 1155
  },
  1520: {
    label: '1520 金币',
    desc: '重量 4.5/力量 18/伤害 18.4/生命 203/尺寸 5/速度 8.3',
    value: 1520
  },
  1950: {
    label: '1950 金币',
    desc: '重量 5/力量 22/伤害 20.1/生命 232.1/尺寸 6/速度 10',
    value: 1950
  },
  2455: {
    label: '2455 金币',
    desc: '重量 5.5/力量 28/伤害 21.8/生命 263.1/尺寸 6/速度 10',
    value: 2455
  },
  3045: {
    label: '3045 金币',
    desc: '重量 6/力量 34/伤害 23.5/生命 295.8/尺寸 6/速度 10',
    value: 3045
  },
  3720: {
    label: '3720 金币',
    desc: '重量 6.5/力量 42/伤害 25.3/生命 330.4/尺寸 6/速度 10',
    value: 3720
  }
}

export const UnitAbility = {
  Ranged: {
    label: 'Ranged',
    desc: 'This creature is able to fire a projectile, damaging enemies at a distance. It deals half its normal damage in melee combat, and there is an area where units are too close for ranged attacks and too far for melee attacks.',
    value: 'Ranged'
  },
  Blaster: {
    label: 'Blaster',
    desc: 'The projectiles fired by this creature strike a large area and knock around friends and foes alike - though only enemies are damaged.',
    value: 'Blaster'
  },
  Flying: {
    label: 'Flying',
    desc: 'This creature is able to fly, moving faster and dodging whenever it can\'t attack. It is able to grab enemies close to death, then drop them from great heights. It can also fly over walls.',
    value: 'Flying'
  },
  Slow: {
    label: 'Slow',
    desc: 'This creature moves slowly across the battlefield.',
    value: 'Slow'
  },
  Tank: {
    label: 'Tank',
    desc: 'This creature has a lot of health and deals little damage.',
    value: 'Tank'
  },
  Sturdy: {
    label: 'Sturdy',
    desc: 'This creature is difficult to knock back and has more health than normal.',
    value: 'Sturdy'
  },
  Savage: {
    label: 'Savage',
    desc: 'This creature deals more damage and has less health.',
    value: 'Savage'
  },
  'Wide Attacks': {
    label: 'Wide Attacks',
    desc: 'This creature deals damage in a larger area, and as such has a higher chance of striking several enemies.',
    value: 'Wide Attacks'
  },
  Lash: {
    label: 'Lash',
    desc: 'This creature lashes out when it attacks, striking enemies in all directions around them.',
    value: 'Lash'
  },
  Protected: {
    label: 'Protected',
    desc: 'This creature is shielded from the first attack against it.',
    value: 'Protected'
  },
  'Projectile Protection': {
    label: 'Projectile Protection',
    desc: 'This creature is shielded from the first three ranged attacks against it.',
    value: 'Projectile Protection'
  },
  Retaliation: {
    label: 'Retaliation',
    desc: 'Whenever this creature takes damage and survives, it will be able to attack again faster.',
    value: 'Retaliation'
  },
  'Quick Strikes': {
    label: 'Quick Strikes',
    desc: 'This creature is able to attack more often',
    value: 'Quick Strikes'
  },
  Healer: {
    label: 'Healer',
    desc: 'This creature is able to heal allies 5 times, each heal restoring health equal to 2x this creature\'s attack.',
    value: 'Healer'
  },
  'First Aid': {
    label: 'First Aid',
    desc: 'This creature is able to heal an ally once, restoring health equal to 2x this creature\'s attack.',
    value: 'First Aid'
  },
  Charge: {
    label: 'Charge',
    desc: 'This creature charges into battle, dealing damage to all enemies it passes on its way.',
    value: 'Charge'
  },
  Leap: {
    label: 'Leap',
    desc: 'This creature leaps into battle, dealing damage to all enemies where it lands. This can also be used to jump over walls or between ships.',
    value: 'Leap'
  },
  'Aura of Light': {
    label: 'Aura of Light',
    desc: 'This creature periodically restores health to all nearby allies.',
    value: 'Aura of Light'
  },
  Afterlife: {
    label: 'Afterlife',
    desc: 'When this creature dies, it summons Anima that will fight for you during battle and remain afterwards.',
    value: 'Afterlife'
  },
  Confusion: {
    label: 'Confusion',
    desc: 'Enemies struck by this creature have a chance to become confused. Confused creatures will wander around aimlessly for a few seconds before returning to normal.',
    value: 'Confusion'
  },
  Knockback: {
    label: 'Knockback',
    desc: 'This creature deals extra knockback when it attacks.',
    value: 'Knockback'
  },
  Summoning: {
    label: 'Summoning',
    desc: 'This creature periodically summons a few temporary <UNITNAMES> that will fight for you during battle.',
    value: 'Summoning'
  },
  Taunt: {
    label: 'Taunt',
    desc: 'This creature attracts attention, and enemies are more likely to target it than other nearby allies.',
    value: 'Taunt'
  },
  Versatile: {
    label: 'Versatile',
    desc: 'This creature is able to fight in melee without incurring the normal ranged unit penalty.',
    value: 'Versatile'
  },
  Teleswap: {
    label: 'Teleswap',
    desc: 'This creature is able to escape melee attackers either by switching place with a friendly melee unit or by swapping place with empty air.',
    value: 'Teleswap'
  },
  Venom: {
    label: 'Venom',
    desc: 'Enemies struck by this creature have a chance to become poisoned. Poisoned creatures will take damage for the rest of the battle. Stronger enemies take slightly more damage.',
    value: 'Venom'
  },
  Ethereal: {
    label: 'Ethereal',
    desc: 'This creature is able to avoid damage from some incoming attacks. Chance of this occuring is higher for creatures weaker than itself.',
    value: 'Ethereal'
  },
  Terror: {
    label: 'Terror',
    desc: 'Enemies struck by this creature have a chance to become terrified. Terrified creatures will run away from combat for a couple of seconds before returning to normal.',
    value: 'Terror'
  },
  Burning: {
    label: 'Burning',
    desc: 'Enemies struck by this creature have a chance to be lit on fire. Burning creatures will take damage five times, and there\'s a chance that the fire will spread to their allies.',
    value: 'Burning'
  },
  Slowing: {
    label: 'Slowing',
    desc: 'Enemies struck by this creature have a chance to become slowed. Slowed creatures will move very slowly for the rest of the battle.',
    value: 'Slowing'
  },
  'Leech Life': {
    label: 'Leech Life',
    desc: 'When this creature deals damage, it will restore health to itself equal to one fourth of the damage dealt.',
    value: 'Leech Life'
  },
  Teleport: {
    label: 'Teleport',
    desc: 'This creature will teleport around the battlefield from time to time.',
    value: 'Teleport'
  },
  'Critical Strike': {
    label: 'Critical Strike',
    desc: 'When this creature attacks, enemies take additional damage equal to one tenth of their maximum health.',
    value: 'Critical Strike'
  },
  Petrification: {
    label: 'Petrification',
    desc: 'Enemies struck by this creature have a chance to become petrified. Petrified creatures will be unable to move or attack for a few seconds.',
    value: 'Petrification'
  },
  Impenetrable: {
    label: 'Impenetrable',
    desc: 'This creature takes half damage from all ranged attacks.',
    value: 'Impenetrable'
  },
  Burst: {
    label: 'Burst',
    desc: 'This creature attacks by exploding, dealing damage to all nearby enemies and dying in the process.',
    value: 'Burst'
  },
  Cinderspirit: {
    label: 'Cinderspirit',
    desc: 'When this creature dies, it summons a group of fireflies. Fireflies attack by exploding.',
    value: 'Cinderspirit'
  },
  'Arrow Rain': {
    label: 'Arrow Rain',
    desc: 'This creature has a chance to fire a rain of arrows, which strikes a larger area and thus damages more enemies.',
    value: 'Arrow Rain'
  },
  'Forest Crown': {
    label: 'Forest Crown',
    desc: 'This creature carries ranged units in its branches, which can shoot at enemies independently of the normal attacks.',
    value: 'Forest Crown'
  },
  Retreat: {
    label: 'Retreat',
    desc: 'When this creature would otherwise die, it instead starts fleeing combat. It will rejoin your army once combat is over.',
    value: 'Retreat'
  },
  'Wind Up': {
    label: 'Wind Up',
    desc: 'This creature attacks less often than other creatures, but its attacks deal more damage.',
    value: 'Wind Up'
  },
  'Death Strike': {
    label: 'Death Strike',
    desc: 'This creature summons a Skeleton for every enemy struck by its attacks.',
    value: 'Death Strike'
  },
  Charm: {
    label: 'Charm',
    desc: 'Enemies struck by this creature that have low health may become charmed. Charmed creatures will join to fight for your side for the rest of the battle and will not return to their original owner.',
    value: 'Charm'
  },
  Rebirth: {
    label: 'Rebirth',
    desc: 'The first time this creature dies, it will return to life after some seconds. It is reborn in an explosion of fire.',
    value: 'Rebirth'
  },
  Aquatic: {
    label: 'Aquatic',
    desc: 'This creature is aquatic in nature. If knocked off a boat, it will re-enter combat unscathed.',
    value: 'Aquatic'
  },
  Caster: {
    label: 'Caster',
    desc: 'This creature is able to cast spells. It will group together with other creatures of the same type to cast larger spells.',
    value: 'Caster'
  },
  Bodyguards: {
    label: 'Bodyguards',
    desc: 'At the start of battle, this creature summons two <UNITNAMES>.',
    value: 'Bodyguards'
  },
  'Blood Aura': {
    label: 'Blood Aura',
    desc: 'When nearby friendly creatures die, this creature becomes larger, deals more damage and gets more health.',
    value: 'Blood Aura'
  },
  Resurrection: {
    label: 'Resurrection',
    desc: 'This creature is able to resurrect some friendly creatures that have died close to it. This works similar to the First Aid tent.',
    value: 'Resurrection'
  },
  Hex: {
    label: 'Hex',
    desc: 'This creature turns enemy creatures at high health into frogs. As frogs, they are much weaker. When the frog dies, the original unit comes back.',
    value: 'Hex'
  },
  Warding: {
    label: 'Warding',
    desc: 'This creature has a chance to avoid damage from elite creatures and has a high chance of being unaffected by spells.',
    value: 'Warding'
  },
  Imitation: {
    label: 'Imitation',
    desc: 'This creature turns into a copy of a nearby enemy. The same enemy unit cannot be imitated twice',
    value: 'Imitation'
  },
  Mutation: {
    label: 'Mutation',
    desc: 'When this creature enters combat, it mutates into one of several versions, each with different abilities',
    value: 'Mutation'
  },
  Undying: {
    label: 'Undying',
    desc: 'When this creature would otherwise die, it goes dormant. After some time, or if the battle is won, it returns to its active state',
    value: 'Undying'
  },
  'Midas Touch': {
    label: 'Midas Touch',
    desc: 'Enemies struck by this creature have a chance to be turned to gold, instantly dying. When this creature dies, it turns to gold. When combat is won, the victor collects gold off the golden corpses',
    value: 'Midas Touch'
  },
  Regeneration: {
    label: 'Regeneration',
    desc: 'This creature regenerates health over time. The more health regenerated, the slower the regeneration becomes',
    value: 'Regeneration'
  },
  Saviour: {
    label: 'Saviour',
    desc: 'This creature can combine human corpses on the battlefield into permanent Survivors',
    value: 'Saviour'
  },
  Release: {
    label: 'Release',
    desc: 'When this creature goes below half health, it has a chance to cast a spell',
    value: 'Release'
  },
  Immortal: {
    label: 'Immortal',
    desc: 'When this creature would otherwise die, it goes into hibernation. It will wake up after 30 seconds or at the end of combat. Each time it goes into hibernation, the chance of surviving next time is halved',
    value: 'Immortal'
  },
  Explosion: {
    label: 'Explosion',
    desc: 'When this creature dies, it explodes, damaging nearby enemies and knocking friends away',
    value: 'Explosion'
  },
  Harpoonprojectile: {
    label: 'Harpoonprojectile',
    desc: 'Enemies struck by this creature\'s ranged attack will be pulled towards this creature',
    value: 'Harpoonprojectile'
  },
  Infest: {
    label: 'Infest',
    desc: 'When this creature attacks, it has a chance to summon <UNITNAMES>',
    value: 'Infest'
  },
  Fortune: {
    label: 'Fortune',
    desc: 'This creature has a much higher chance of having luck strikes. Luck strikes deal 3x damage, strike a larger area and have more knockback',
    value: 'Fortune'
  },
  Budding: {
    label: 'Budding',
    desc: 'When this creature takes damage, it has a chance to summon <UNITNAMES>',
    value: 'Budding'
  },
  Splitting: {
    label: 'Splitting',
    desc: 'When this creature dies, it summons two <UNITNAMES>',
    value: 'Splitting'
  },
  'Long Ranged': {
    label: 'Long Ranged',
    desc: 'This creature\'s ranged attacks can strike enemies further away than other ranged creatures would',
    value: 'Long Ranged'
  },
  'Short Ranged': {
    label: 'Short Ranged',
    desc: 'This creature\'s ranged attacks have a shorter range than other ranged creatures, but can also hit enemies closer to them',
    value: 'Short Ranged'
  },
  'Aura of Power': {
    label: 'Aura of Power',
    desc: 'Creatures close to this will have their health, attack and speed increased',
    value: 'Aura of Power'
  },
  Rage: {
    label: 'Rage',
    desc: 'When this unit takes damage, its damage and speed increases',
    value: 'Rage'
  },
  Taxpayer: {
    label: 'Taxpayer',
    desc: 'Each day, this creature pays 2 gold to its owner for each power it has,',
    value: 'Taxpayer'
  },
  Thorns: {
    label: 'Thorns',
    desc: 'Whenever this creature is hit by a melee attack, the attacker takes damage equal to half this creature\'s attack,',
    value: 'Thorns'
  },
  'Fire Shield': {
    label: 'Fire Shield',
    desc: 'Whenever this creature is hit by a melee attack, the attacker is set on fire,',
    value: 'Fire Shield'
  },
  Tower: {
    label: 'Tower',
    desc: 'This creature is unable to move but has infinite range,',
    value: 'Tower'
  },
  Chaos: {
    label: 'Chaos',
    desc: 'Enemies struck by this creature have a chance to be teleported away,',
    value: 'Chaos'
  },
  Displacement: {
    label: 'Displacement',
    desc: 'Whenever this creature is hit by a melee attack, the attacker has a chance to be teleported away,',
    value: 'Displacement'
  },
  Buffer: {
    label: 'Buffer',
    desc: 'When the hero controlling these units casts a spell costing mana, each can refund 1 mana once per battle,',
    value: 'Buffer'
  },
  Empower: {
    label: 'Empower',
    desc: 'Increases the effective spellpower of the hero controlling these units by 1 spellpower for every 10 power worth of these creatures',
    value: 'Empower'
  },
  Inert: {
    label: 'Inert',
    desc: 'This creature cannot be affected by poison, confusion, fear, petrification or burning',
    value: 'Inert'
  }
}

export const UnitSpell = {
  Haste: {
    label: 'Haste',
    value: 'Haste'
  },
  'First Strike': {
    label: 'First Strike',
    value: 'First Strike'
  },
  Fortune: {
    label: 'Fortune',
    value: 'Fortune'
  },
  Bloodlust: {
    label: 'Bloodlust',
    value: 'Bloodlust'
  },
  Precision: {
    label: 'Precision',
    value: 'Precision'
  },
  Bless: {
    label: 'Bless',
    value: 'Bless'
  },
  Disguise: {
    label: 'Disguise',
    value: 'Disguise'
  },
  Curse: {
    label: 'Curse',
    value: 'Curse'
  },
  Slow: {
    label: 'Slow',
    value: 'Slow'
  },
  Weakness: {
    label: 'Weakness',
    value: 'Weakness'
  },
  Quicksand: {
    label: 'Quicksand',
    value: 'Quicksand'
  },
  'Disrupting Ray': {
    label: 'Disrupting Ray',
    value: 'Disrupting Ray'
  },
  'Destroy Undead': {
    label: 'Destroy Undead',
    value: 'Destroy Undead'
  },
  'Meteor Shower': {
    label: 'Meteor Shower',
    value: 'Meteor Shower'
  },
  Inferno: {
    label: 'Inferno',
    value: 'Inferno'
  },
  Cure: {
    label: 'Cure',
    value: 'Cure'
  },
  'Holy Light': {
    label: 'Holy Light',
    value: 'Holy Light'
  },
  'Air Shield': {
    label: 'Air Shield',
    value: 'Air Shield'
  },
  Forcefield: {
    label: 'Forcefield',
    value: 'Forcefield'
  },
  Stoneskin: {
    label: 'Stoneskin',
    value: 'Stoneskin'
  },
  'Fire Shield': {
    label: 'Fire Shield',
    value: 'Fire Shield'
  },
  Shield: {
    label: 'Shield',
    value: 'Shield'
  },
  'Energy Blast': {
    label: 'Energy Blast',
    value: 'Energy Blast'
  },
  'Acid Splash': {
    label: 'Acid Splash',
    value: 'Acid Splash'
  },
  'Frost Ring': {
    label: 'Frost Ring',
    value: 'Frost Ring'
  },
  Deathripple: {
    label: 'Deathripple',
    value: 'Deathripple'
  },
  Hypnotize: {
    label: 'Hypnotize',
    value: 'Hypnotize'
  },
  Blind: {
    label: 'Blind',
    value: 'Blind'
  },
  Berserk: {
    label: 'Berserk',
    value: 'Berserk'
  },
  Resurrection: {
    label: 'Resurrection',
    value: 'Resurrection'
  },
  'Animate Dead': {
    label: 'Animate Dead',
    value: 'Animate Dead'
  },
  'Magic Arrow': {
    label: 'Magic Arrow',
    value: 'Magic Arrow'
  },
  Iceball: {
    label: 'Iceball',
    value: 'Iceball'
  },
  'Lightning Bolt': {
    label: 'Lightning Bolt',
    value: 'Lightning Bolt'
  },
  Fireball: {
    label: 'Fireball',
    value: 'Fireball'
  },
  Explosion: {
    label: 'Explosion',
    value: 'Explosion'
  },
  'Flame Strike': {
    label: 'Flame Strike',
    value: 'Flame Strike'
  },
  'Ice Bolt': {
    label: 'Ice Bolt',
    value: 'Ice Bolt'
  },
  'Chain Lightning': {
    label: 'Chain Lightning',
    value: 'Chain Lightning'
  },
  'Deflect Missile': {
    label: 'Deflect Missile',
    value: 'Deflect Missile'
  },
  'Summon Goblins': {
    label: 'Summon Goblins',
    value: 'Summon Goblins'
  },
  'Elemental Rock': {
    label: 'Elemental Rock',
    value: 'Elemental Rock'
  },
  'Summon Horrors': {
    label: 'Summon Horrors',
    value: 'Summon Horrors'
  },
  'Summon Anima': {
    label: 'Summon Anima',
    value: 'Summon Anima'
  },
  'Elemental Air': {
    label: 'Elemental Air',
    value: 'Elemental Air'
  },
  'Elemental Thunder': {
    label: 'Elemental Thunder',
    value: 'Elemental Thunder'
  },
  'Summon Imps': {
    label: 'Summon Imps',
    value: 'Summon Imps'
  },
  'Elemental Fire': {
    label: 'Elemental Fire',
    value: 'Elemental Fire'
  },
  'Elemental Magma': {
    label: 'Elemental Magma',
    value: 'Elemental Magma'
  },
  'Summon Worms': {
    label: 'Summon Worms',
    value: 'Summon Worms'
  },
  'Elemental Water': {
    label: 'Elemental Water',
    value: 'Elemental Water'
  },
  'Elemental Ice': {
    label: 'Elemental Ice',
    value: 'Elemental Ice'
  },
  'Elemental Mana': {
    label: 'Elemental Mana',
    value: 'Elemental Mana'
  },
  'Rain of Arrows': {
    label: 'Rain of Arrows',
    value: 'Rain of Arrows'
  },
  'Siege Rain': {
    label: 'Siege Rain',
    value: 'Siege Rain'
  },
  Duplicate: {
    label: 'Duplicate',
    value: 'Duplicate'
  },
  'Rain of Frogs': {
    label: 'Rain of Frogs',
    value: 'Rain of Frogs'
  },
  'Frog Torrent': {
    label: 'Frog Torrent',
    value: 'Frog Torrent'
  },
  Gigantism: {
    label: 'Gigantism',
    value: 'Gigantism'
  },
  'Army of Giants': {
    label: 'Army of Giants',
    value: 'Army of Giants'
  },
  Chaos: {
    label: 'Chaos',
    value: 'Chaos'
  },
  'Comet Strikes': {
    label: 'Comet Strikes',
    value: 'Comet Strikes'
  },
  'Comet Rain': {
    label: 'Comet Rain',
    value: 'Comet Rain'
  },
  'Corpse Explosion': {
    label: 'Corpse Explosion',
    value: 'Corpse Explosion'
  },
  Transform: {
    label: 'Transform',
    value: 'Transform'
  },
  Evolve: {
    label: 'Evolve',
    value: 'Evolve'
  },
  Hex: {
    label: 'Hex',
    value: 'Hex'
  },
  'Second Life': {
    label: 'Second Life',
    value: 'Second Life'
  },
  'Salt Shower': {
    label: 'Salt Shower',
    value: 'Salt Shower'
  },
  'Salt Blast': {
    label: 'Salt Blast',
    value: 'Salt Blast'
  },
  'Salt Armor': {
    label: 'Salt Armor',
    value: 'Salt Armor'
  }
}

export const UnitTechnicalAbility = {
  Fireprojectile: {
    label: 'Fireprojectile',
    value: 'Fireprojectile'
  },
  Rockprojectile: {
    label: 'Rockprojectile',
    value: 'Rockprojectile'
  },
  Bulletprojectile: {
    label: 'Bulletprojectile',
    value: 'Bulletprojectile'
  },
  Lightningprojectile: {
    label: 'Lightningprojectile',
    value: 'Lightningprojectile'
  },
  Manaprojectile: {
    label: 'Manaprojectile',
    value: 'Manaprojectile'
  },
  Iceprojectile: {
    label: 'Iceprojectile',
    value: 'Iceprojectile'
  },
  Laserprojectile: {
    label: 'Laserprojectile',
    value: 'Laserprojectile'
  },
  Boneprojectile: {
    label: 'Boneprojectile',
    value: 'Boneprojectile'
  },
  Inkprojectile: {
    label: 'Inkprojectile',
    value: 'Inkprojectile'
  },
  Sirenprojectile: {
    label: 'Sirenprojectile',
    value: 'Sirenprojectile'
  },
  Javelinprojectile: {
    label: 'Javelinprojectile',
    value: 'Javelinprojectile'
  },
  Dynamiteprojectile: {
    label: 'Dynamiteprojectile',
    value: 'Dynamiteprojectile'
  },
  Fast: {
    label: 'Fast',
    value: 'Fast'
  },
  Flimsy: {
    label: 'Flimsy',
    value: 'Flimsy'
  },
  Feeble: {
    label: 'Feeble',
    value: 'Feeble'
  },
  // TODO: 需要支持参数
  // Health: {
  //   label: 'Health',
  //   value: 'Health'
  // },
  // Damage: {
  //   label: 'Damage',
  //   value: 'Damage'
  // },
  Small: {
    label: 'Small',
    value: 'Small'
  },
  Big: {
    label: 'Big',
    value: 'Big'
  },
  Micro: {
    label: 'Micro',
    value: 'Micro'
  },
  Shrink: {
    label: 'Shrink',
    value: 'Shrink'
  },
  Hover: {
    label: 'Hover',
    value: 'Hover'
  }
}

export const AttackType = {
  0: {
    label: '防御型',
    value: 0
  },
  1: {
    label: '攻击型',
    value: 1
  },
  2: {
    label: '魔法型',
    value: 2
  }
}

export const Living = {
  '-2': {
    label: '瘟疫',
    value: -2
  },
  '-1': {
    label: '亡灵',
    value: -1
  },
  0: {
    label: '无生命（机械、元素）',
    value: 0
  },
  1: {
    label: '活物',
    value: 1
  },
  2: {
    label: '人类',
    value: 2
  },
  3: {
    label: '野兽',
    value: 3
  }
}

export const UnitGroup = {
  'The Seven Fears': {
    label: 'The Seven Fears',
    value: 'The Seven Fears'
  },
  'Throne of Etereals': {
    label: 'Throne of Etereals',
    value: 'Throne of Etereals'
  },
  'Tribes of Astoria': {
    label: 'Tribes of Astoria',
    value: 'Tribes of Astoria'
  },
  Underworld: {
    label: 'Underworld',
    value: 'Underworld'
  },
  'Alliance of the Frontier': {
    label: 'Alliance of the Frontier',
    value: 'Alliance of the Frontier'
  },
  'Cold Illuminates': {
    label: 'Cold Illuminates',
    value: 'Cold Illuminates'
  },
  Conquistadors: {
    label: 'Conquistadors',
    value: 'Conquistadors'
  },
  'Cults of Serpents': {
    label: 'Cults of Serpents',
    value: 'Cults of Serpents'
  },
  'Dark Cultists': {
    label: 'Dark Cultists',
    value: 'Dark Cultists'
  },
  'Earth\'s Patrons': {
    label: 'Earth\'s Patrons',
    value: 'Earth\'s Patrons'
  },
  'Echelons of Magic': {
    label: 'Echelons of Magic',
    value: 'Echelons of Magic'
  },
  'Followers of Air': {
    label: 'Followers of Air',
    value: 'Followers of Air'
  },
  'Imperial Legion': {
    label: 'Imperial Legion',
    value: 'Imperial Legion'
  },
  'Infernal Cultists': {
    label: 'Infernal Cultists',
    value: 'Infernal Cultists'
  },
  'Mountain King': {
    label: 'Mountain King',
    value: 'Mountain King'
  },
  'Myriad Swarm': {
    label: 'Myriad Swarm',
    value: 'Myriad Swarm'
  },
  'Noble Reign': {
    label: 'Noble Reign',
    value: 'Noble Reign'
  },
  'Pact of Nature': {
    label: 'Pact of Nature',
    value: 'Pact of Nature'
  },
  'Passionates of Fire': {
    label: 'Passionates of Fire',
    value: 'Passionates of Fire'
  },
  Seakeepers: {
    label: 'Seakeepers',
    value: 'Seakeepers'
  },
  'The Conflux': {
    label: 'The Conflux',
    value: 'The Conflux'
  },
  'The Corrupt': {
    label: 'The Corrupt',
    value: 'The Corrupt'
  },
  'The Overgrowth': {
    label: 'The Overgrowth',
    value: 'The Overgrowth'
  }
}
